import * as actionTypes from '../../actions/actionTypes';
const initialState = {
  response: null,
  error: null,
  isLoading: false,
  surveyLibraries: [],
  surveyDetails: {},
  surveyQuestions: null,
  LaunchSurveyDetails: {},
  hasSections: 0,
  staticSurveyStatus: 0,
  surveyReportDetails: [],
  surveyOverviewDetails: {},
  surveyTopDepartments: [],
  surveyReportsData: [],
  surveyTopLocations: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

  case actionTypes.GET_SURVEY_LIBRARIES:
    return {
      ...state,
      isLoading: false,
      surveyLibraries: action.surveyLibraries,
    };

  case actionTypes.GET_SURVEY_DETAILS:
    return {
      ...state,
      isLoading: false,
      surveyDetails: action.surveyDetails,
    };

  case actionTypes.SURVEY_LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.SURVEY_LOADING_FALSE:
    return {
      ...state,
      isLoading: false
    };
  case actionTypes.FETCH_SURVEY_QUESTIONS:
    return {
      ...state,
      surveyQuestions: action.surveyQuestions,
      hasSections: action.hasSections,
      LaunchSurveyDetails: action.LaunchSurveyDetails,
      staticSurveyStatus: action.staticSurveyStatus
    };
  case actionTypes.EMPTY_SURVEY_QUESTIONS:
    return {
      ...state,
      surveyQuestions: null,
      staticSurveyStatus: 0
    };
  case actionTypes.GET_SURVEY_REPORT_DETAILS:
    return {
      ...state,
      surveyReportDetails: action.surveyReportDetails,
      surveyOverviewDetails: {},
      isLoading: false
    };
  case actionTypes.GET_SURVEY_OVERVIEW_DETAILS:
    return {
      ...state,
      surveyOverviewDetails: action.surveyOverviewDetails,
      isLoading: false
    };
  case actionTypes.GET_SURVEY_TOP_LOCATIONS:
    return {
      ...state,
      surveyTopLocations: action.surveyTopLocations,
      isLoading: false
    };
  case actionTypes.GET_SURVEY_TOP_DEPARTMENTS:
    return {
      ...state,
      surveyTopDepartments: action.surveyTopDepartments,
      isLoading: false
    };
  case actionTypes.GET_SURVEY_REPORTS_DATA:
    return {
      ...state,
      surveyReportsData: action.surveyReportsData,
      isLoading: false
    };
  default: break;
  }
  return state;
};

export default reducer;