/* eslint-disable no-console, no-undef, no-case-declarations, camelcase */

import * as actionTypes from '../../actions/actionTypes';
import { descendingOrderSort } from '../../../utils/methods';

const initialState = {
  response: null,
  errorMessage: null,
  isLoading: false,
  comments: null,
  commentError: null,
  success: null,
  postCommentError: null,
  inspirationError: null,
  successLikeOrUnlike: null,
  likeUnlikeError: null,
  requestsError: null,
  friendRequests: [],
  requestAccepted: null,
  acceptRequestError: null,
  networkError: null,
  activityFeeds: [],
  activityFeedsError: null,
  disableButton: false,
  replySuccess: null,
  replyError: null,
  userPosts: null,
  postsError: null,
  friendList: null,
  friendListError: null,
  wellnessExpert: null,
  wellnessExpertError: null,
  sendRequestError: null,
  requestSent: null,
  count: null,
  userPostImages: null,
  imagesError: null,
  otherDetails: null,
  exemplifiesValues: [],
  companyExemplifiesValues: [],
  recognitionIcons: null,
  deletedRecognition: null,
  errorToAddExemplifiesValue: null,
  successAddedEXemplifiesValue: null,
  workoutHistory: null,
  dayWiseWorkoutHistory: null,
  workoutActivityCount: null,
  workoutActivityBytypes: null,
  suggestionList: null,
  sentRequest: null,
  challengesList : null,
  eventList: null,
  recommendedListStatus: null,
  postLikeDetails: [],
  buddyDetails: null,
  sendRequest: null,
  removeBuddy: null,
  birthdayList : {},
  birthdayAnniversaryWishStatus: {},
  monthlyActivityDateList: {},
  sendRequestsData: [],
  inspirationPost: [],
  userFeedSuccess: [],
  userFeedError: [],
  amigoResponse: null,
  anniversaryBirthdayUsersData: null
};

const updateList = (list, id, status, value) => {
  let data = list && ( list.response && list.response.length > 0 ? list.response : list)
  let index = data && data.findIndex((post) => post.id === id);
  let updationData = [...data];
  if(index > -1) {
    switch(status) {
    case 'comment':
      updationData[index].comment_count += 1;
      break;
  
    case 'like':
      updationData[index]['like_count'] += 1;
      updationData[index]['own_like_post'] = 1;
      break;
      
    case 'unlike':
      updationData[index]['like_count'] -= 1;
      updationData[index]['own_like_post'] = 0;
      break;
  
    case 'edit':
      let post = {...updationData[index], ...value};
      updationData[index] = post;
      break;
  
    case 'delete':
      updationData.splice(index, 1);
      break;
    }
  }
  return updationData;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

  case actionTypes.SOCIAL_LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.GET_SOCIAL_POSTS:
    let response = [];
    let actualResponse = state && state.response ?(state.response.response ? state.response.response : state.response):null
    if(action.pageNumber > 1){
      action.response.response = [
        ...actualResponse,
        ...action.response.response
      ]}

    response = action.response
    return {
      ...state,
      response,
      count: action.count,
      isLoading: false
    };

  case actionTypes.GET_MY_AMIGO_SOCIAL_POSTS:
    let amigoResponse = [];
    if(action.pageNumber > 1){
      action.amigoResponse.response = [ ...state.amigoResponse.response,...action.amigoResponse.response]
    }
    amigoResponse =  action.amigoResponse;
    
    return {
      ...state,
      amigoResponse,
      count: action.count,
      isLoading: false
    };

  case actionTypes.FAILED_GET_SOCIAL_POSTS:
    return {
      ...state,
      errorMessage: action.error,
    };

  case actionTypes.GET_COMMENTS_ON_POST:
    state.response && state.response.response ? state.response.response.map((posts) =>{
      if(posts && posts.post_data && posts.post_data.id === action.id && action.comments && action.comments.length > 0){
        posts.post_data.comment_count = action.comments.length
      }
    }) : state.response && state.response.map((posts) =>{
      if(posts && posts.post_data && posts.post_data.id === action.id && action.comments && action.comments.length > 0){
        posts.post_data.comment_count = action.comments.length
      }
    })
    return {
      ...state,
      comments: descendingOrderSort(action.comments),
      isLoading: false
    };

  case actionTypes.FAILED_GET_COMMENTS_ON_POST:
    return {
      ...state,
      commentError: action.commentError,
      isLoading: false,
      comments: []
    };

  case actionTypes.GET_INSPIRATION_POST_BY_ID:
    return {
      ...state,
      inspirationPost: action.inspirationPost
    };
  

  case actionTypes.ADD_COMMENT_ON_POST:
    return {
      ...state,
      success: action.success
    };

  case actionTypes.FAILED_ADD_COMMENT_ON_POST:
    return {
      ...state,
      postCommentError: action.postCommentError
    };

  case actionTypes.ADD_SOCIAL_INSPIRATION:
    return {
      ...state,
      success: action.success,
      isLoading: false
    };

  case actionTypes.FAILED_ADD_SOCIAL_INSPIRATION:
    return {
      ...state,
      inspirationError: action.inspirationError,
      isLoading: false
    };

  case actionTypes.LIKE_UNLIKE_POST:
    return {
      ...state,
      successLikeOrUnlike: action.successLikeOrUnlike
    };

  case actionTypes.FAILED_LIKE_UNLIKE_POST:
    return {
      ...state,
      likeUnlikeError: action.inspirationError
    };

  case actionTypes.GET_FRIEND_REQUESTS:
    return {
      ...state,
      friendRequests: action.friendRequests
    };

  case actionTypes.FAILED_GET_FRIEND_REQUESTS:
    return {
      ...state,
      requestsError: action.requestsError
    };

  case actionTypes.ACCEPT_REJECT_FRIEND_REQUEST:
    return {
      ...state,
      requestAccepted: action.requestAccepted
    };

  case actionTypes.FAILED_ACCEPT_REJECT_FRIEND_REQUEST:
    return {
      ...state,
      acceptRequestError: action.acceptRequestError
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError,
      isLoading: false
    };

  case actionTypes.GET_ACTIVITY_FEEDS:
    return {
      ...state,
      activityFeeds: descendingOrderSort(action.activityFeeds)
    };
  case actionTypes.FAILED_GET_ACTIVITY_FEEDS:
    return {
      ...state,
      activityFeedsError: action.activityFeedsError
    };

  case actionTypes.DISABLE_BUTTON:
    return {
      ...state,
      disableButton: true
    };

  case actionTypes.ENABLE_BUTTON:
    return {
      ...state,
      disableButton: false
    };

  case actionTypes.OTHER_USER_DETAIL:
    return {
      ...state,
      otherDetails: action.otherDetails
    }

  case actionTypes.ADD_COMMENT_REPLY_ON_POST:
    return {
      ...state,
      replySuccess: action.replySuccess
    };

  case actionTypes.FAILED_ADD_COMMENT_REPLY_ON_POST:
    return {
      ...state,
      replyError: action.replyError
    };


  case actionTypes.GET_ALL_MY_AMIGO_INSPIRATION_POST:
    return {
      ...state,
      userFeedSuccess: action.userFeedSuccess
    };

  case actionTypes.FAILED_GET_ALL_MY_AMIGO_INSPIRATION_POST:
    return {
      ...state,
      userFeedError: action.userFeedError
    };

  case actionTypes.UPDATE_POST:
    const newResponse = state.response && updateList(state.response, action.id, action.value);
    const newUserPostImages = state.userPostImages && updateList(state.userPostImages, action.id, action.value);
    const newUserPosts = state.userPosts && updateList(state.userPosts, action.id, action.value);
    return {
      ...state,
      response: newResponse,
      userPostImages: newUserPostImages,
      userPosts: newUserPosts
    };

  case actionTypes.UPDATE_EDIT_POSTS:
    const newResponseForEdit = state.response && updateList(state.response, action.id, 'edit', action.value);
    const newUserPostImagesForEdit = state.userPostImages && updateList(state.userPostImages, action.id, 'edit', action.value);
    const newUserPostsForEdit = state.userPosts && updateList(state.userPosts, action.id, 'edit', action.value);

    return {
      ...state,
      response: newResponseForEdit,
      userPostImages: newUserPostImagesForEdit,
      userPosts: newUserPostsForEdit
    };

  case actionTypes.UPDATE_LIST:
    const newResponseForDelete = state.response && updateList(state.response, action.id, 'delete');
    const newUserPostImagesForDelete = state.userPostImages && updateList(state.userPostImages, action.id, 'delete');
    const newUserPostsForDelete = state.userPosts && updateList(state.userPosts, action.id, 'delete');

    return {
      ...state,
      response: newResponseForDelete,
      userPostImages: newUserPostImagesForDelete,
      userPosts: newUserPostsForDelete
    };

  case actionTypes.GET_USER_POSTS:
    return {
      ...state,
      userPosts: action.userPosts
    };

  case actionTypes.FAILED_GET_USER_POSTS:
    return {
      ...state,
      postsError: action.postsError
    };

  case actionTypes.GET_USER_FRIENDS_LIST:
    return {
      ...state,
      friendList: action.friendList
    };

  case actionTypes.FAILED_GET_USER_FRIENDS_LIST:
    return {
      ...state,
      friendListError: action.friendListError
    };

  case actionTypes.GET_WELLNESS_EXPERT:
    return {
      ...state,
      wellnessExpert: action.wellnessExpert
    };

  case actionTypes.FAILED_GET_WELLNESS_EXPERT:
    return {
      ...state,
      wellnessExpertError: action.wellnessExpertError
    };

  case actionTypes.REMOVE_FRIEND:
    const friendIndex = state.friendList.findIndex((friend) => friend.friend_id === action.friendId);
    let friendList = [...state.friendList];
    if(friendIndex > -1) {
      friendList.splice(friendIndex, 1);
    }
    return {
      ...state,
      friendList: friendList
    };

  case actionTypes.FAILED_REMOVE_FRIEND:
    return {
      ...state,
      removeFriendError: action.removeFriendError
    };

  case actionTypes.GET_USER_POST_IMAGES:
    return {
      ...state,
      userPostImages: action.userPostImages
    };

  case actionTypes.FAILED_GET_USER_POST_IMAGES:
    return {
      ...state,
      imagesError: action.imagesError
    };
  case actionTypes.GET_COMPANY_EXEMPLIFIES_VALUES: {
    return {
      ...state,
      exemplifiesValues: action.exemplifiesValues
    }
  }  
  case actionTypes.GET_COMPANY_EXEMPLIFIES: {
    return {
      ...state,
      companyExemplifiesValues: action.companyExemplifiesValues
    }
  }
  case actionTypes.UPDATE_COMPANY_EXEMPLIFY_VALUES: {
    return {
      ...state,
      updateExemplifiesValues: action.updateExemplifiesValues
    }
  }

  
  case actionTypes.FAILED_ADD_COMPANY_EXEMPLIFIES_VALUES:
    return {
      ...state,
      errorToAddExemplifiesValue: action.errorToAddExemplifiesValue
    };

  case actionTypes.ADD_COMPANY_EXEMPLIFIES_VALUES: {
    return {
      ...state,
      successAddedEXemplifiesValue: action.successAddedEXemplifiesValue
    }
  }

  case actionTypes.GET_RECOGNITIONS_ICONS: {
    return {
      ...state,
      recognitionIcons: action.recognitionIcons
    }
  }
  
  case actionTypes.DELETE_COMPANY_EXEMPLIFIES_VALUES: {
    return {
      ...state,
      deletedRecognition: action.deletedRecognition
    }
  }

  case actionTypes.GET_WORKOUT_HISTORY: {
    return {
      ...state,
      workoutHistory: action.workoutHistory
    }
  }

  case actionTypes.GET_DAY_WISE_WORKOUT_HISTORY: {
    return {
      ...state,
      dayWiseWorkoutHistory: action.dayWiseWorkoutHistory
    }
  }
  case actionTypes.GET_WORKOUT_ACTIVITY_COUNT: {
    return {
      ...state,
      workoutActivityCount: action.workoutActivityCount
    }
  }
  case actionTypes.GET_WORKOUT_ACTIVITY_BY_TYPES: {
    return {
      ...state,
      workoutActivityBytypes: action.workoutActivityBytypes
    }
  }
  case actionTypes.GET_SUGGESTION_LIST: {
    return {
      ...state,
      suggestionList: action.suggestionList
    }
  }
  case actionTypes.SENT_SUGGESTION_FRIEND_REQUEST: {
    return {
      ...state,
      sentRequest: action.sentRequest
    }
  }
  case actionTypes.GET_CHALLENGES_LIST:
    return {
      ...state,
      challengesList: action.challengesList
    }
  case actionTypes.GET_EVENT_LIST:
    return {
      ...state,
      eventList: action.eventList
    }
  case actionTypes.GET_RECOMMENDED_LIST_STATUS:
    return {
      ...state,
      recommendedListStatus: action.recommendedListStatus
    }
  case actionTypes.GET_LIKE_DETAILS:
    return {
      ...state,
      postLikeDetails: action.postLikeDetails
    }
  case actionTypes.GET_UNFRIEND_USER:
    return {
      ...state,
      unfriendUser: action.unfriendUser
    }
  case actionTypes.ADD_BUDDY_ON_POST: {
    return {
      ...state,
      buddyDetails: action.buddyDetails
    }
  }

  case actionTypes.SEND_BUDDY_REQUEST: {
    return {
      ...state,
      sendRequest: action.sendRequest
    }
  }

  case actionTypes.REMOVE_BUDDY: {
    return {
      ...state,
      removeBuddy: action.removeBuddy
    }
  }

  case actionTypes.GET_BIRTHDAY_LIST: {
    return {
      ...state,
      birthdayList: action.birthdayList
    }
  }
  case actionTypes.GET_BIRTHDAY_ANNIVERSARY_WISH_STATUS: {
    return {
      ...state,
      birthdayAnniversaryWishStatus: action.birthdayAnniversaryWishStatus
    }
  }
  case actionTypes.SET_FEEDS_DATA_GOBALLY: {
    return {
      ...state,
      response: action.updatedFeedsData
    }
  }
  case actionTypes.GET_MONTHLY_ACTIVITY_STATUS: {
    return {
      ...state,
      monthlyActivityDateList: action.monthlyWorkoutStatus
    }
  }
  case actionTypes.GET_SEND_FRIEND_REQUESTS:
    return {
      ...state,
      sendRequestsData: action.sendRequestsData
    };
  case actionTypes.GET_ANNIVERSARY_BIRTHDAY:
    return {
      ...state,
      anniversaryBirthdayUsersData: action.anniversaryBirthdayUsersData
    };  
  default: break;
  }
  return state;
};

export default reducer;
