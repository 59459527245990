import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  companyAndCustomer: {},
  isLoading: false,
  networkError: null,
  invoices: [],
  allCardsDetails: [],
  allAddons: [],
  currentSubsciptionDetails: {},
  billingTabStatus: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.GET_COMPANY_AND_CUSTOMER_DETAILS:
    return {
      ...state,
      companyAndCustomer: action.companyAndCustomer,
      allCardsDetails: [],
      currentSubsciptionDetails: {},
      invoices: []
    }
  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError
    };
  case actionTypes.PAYMENT_LOADING_TRUE:
    return {
      ...state,
      isLoading: true
    };
  case actionTypes.PAYMENT_LOADING_FALSE:
    return {
      ...state,
      isLoading: false
    };
  case actionTypes.GET_ALL_PLANS:
    return {
      ...state,
      allPlans: action.allPlans
    };
  case actionTypes.GET_ALL_INVOICES:{
    return {
      ...state,
      invoices: action.invoices
    }
  }
  case actionTypes.GET_ALL_CARDS_DETAILS:{
    return {
      ...state,
      allCardsDetails: action.allCardsDetails
    }
  }
  case actionTypes.GET_ALL_ADDONS_DETAILS:{
    return {
      ...state,
      allAddons: action.allAddons
    }
  }
  case actionTypes.GET_COMPANY_SUBSCRPTION_AND_ADDONS_DETAILS:{
    return {
      ...state,
      currentSubsciptionDetails: action.currentSubsciptionDetails
    }
  }
  case actionTypes.GET_BILLING_TAB_STATUS:{
    return {
      ...state,
      billingTabStatus: action.billingTabStatus
    }
  }
  default:
    break;
  }
  return state;
};

export default reducer;